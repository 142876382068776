import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import TaskList from './Components/task-list';
import EditTask from './Components/edit-task';
import CreateTask from './Components/create-task';

import {Navbar, Nav} from 'react-bootstrap';


function App() {
    return (
      <div>
          <div className="NavBar">
              <a className="NavLink" href='/'>Tasks-List</a>
              <a className="NavLink" href='/create'>Create</a>
          </div>

          <Router>  
              <Route path='/' exact component={TaskList}/>
              <Route path='/edit' component={EditTask}/>
              <Route path="/create" component={CreateTask} />
          </Router>
      </div>
    );
}

export default App;
