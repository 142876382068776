import React from 'react';
import {Form} from 'react-bootstrap';
import './../assets/css/edit-task.css';
import axios from 'axios';


export default class EditTask extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title: '',
            description: '',
            priority: 1,
            message: false
        };
    }

    componentDidMount(){
        const id = window.location.pathname.split("/").pop();
        axios.get(`https://task-app-server-jatin.herokuapp.com/tasks/${id}`)
            .then(res => {
                const task = res.data.task;
                this.setState({
                    title: task.title,
                    description: task.description,
                    priority: task.priority
                });
                console.log(this.state);
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    updateTask(){
        if(this.state.title.length == 0){
            alert('title cant be empty.')
            return;
        }

        let newTask = {
            title: this.state.title,
            description: this.state.description,
            priority: this.state.priority
        };

        const id = window.location.pathname.split("/").pop();
        // const url = 
        axios.patch(`https://task-app-server-jatin.herokuapp.com/tasks/${id}`, newTask)
            .then(res => {
                this.setState({
                    title: '',
                    description: '',
                    priority: 1,
                    message: true
                });
            })
            .catch((err) => {
                console.log(err)
            });
    }

    render(){
        return (
            <div className="Form-Box">
                <div className="field">
                    <label className="label">Title</label>
                    <div className="control">
                        <input 
                            className="input" 
                            type="text" 
                            placeholder="e.g Learn cooking"
                            value = {this.state.title}
                            onChange={(e) => this.setState({title: e.target.value})}
                        />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Decsription</label>
                    <textarea 
                        className="textarea is-small" 
                        placeholder="e.g Prepare a dish..."
                        value = {this.state.description}
                        onChange={(e) => this.setState({description: e.target.value})}  
                    />
                </div>

                <div className="field">
                    <div className="control">
                        <label className="label">Priority (5-Highest 1-Lowest)</label>
                        <div className="select">
                            <select 
                                value = {this.state.priority}
                                onChange={(e) => this.setState({priority: e.target.value})}
                            >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>

                </div> 
                
                <div className="field">
                    <button className="button is-primary" onClick={() => this.updateTask()}>Update Task</button>  
                </div>

                <div className="field">
                    {(this.state.message)? "Task updated succesfully!": null}
                </div>
            </div>
        );
    }
}