import React, { Component } from 'react';
import axios from 'axios';
import './../assets/css/task-list.css';

export default class TaskList extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            tasks : []
        };
    }

    componentDidMount(){
        setInterval(() => {
            this.updateTasks();
        }, 2000);
    }

    updateTasks(){
        axios.get('https://task-app-server-jatin.herokuapp.com/tasks')
            .then(res => {
                this.setState({
                    tasks : res.data,
                    loading: false
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

  
    handleDelete = (id) => {
        axios.delete(`https://task-app-server-jatin.herokuapp.com/tasks/${id}`)
            .then((res) => {
                this.updateTasks();
            })
            .catch((err) => {
                console.log(err);
            });
    }

//    generateDate = (d) => {
       

    getTasksElement(tasks){
        return tasks.map((task, taskIdx) => {
            return (
                <div className="Task-outer">
                    <article className="message is-info">
                        <div className="message-header">
                            {task.title}
                            <button 
                                className="delete" 
                                onClick={() => this.handleDelete(task._id)}
                            >    
                            </button>
                        </div>

                        <div className="message-body">
                            <p>{task.description}</p>
                            <p>Priority - {task.priority}</p>
                            <p>Date - {task.date.slice(0,10)}</p>
                        </div>

                        <a href={`/edit/${task._id}`}>
                            <button className="button is-rounded">
                                Edit
                            </button>
                        </a>

                        <br/>
                    </article>
                </div>
            );
        });
    }

    render() {
        return (
            <div>
                {(this.state.loading) 
                    ?  
                        <div>Loading...</div>
                    :                            
                    (this.state.tasks.length == 0) 
                        ?
                            <div>No tasks to show!</div>
                        :
                            this.getTasksElement(this.state.tasks) 
                }
            </div>
        )
    }
}